export const dateToIndexerValue = (indexerField: string, date: any): any => {
    if(indexerField.startsWith('t')){
        return date ? new Date(date).toLocaleDateString("nl-BE") : ''
    }
    if(indexerField.startsWith('d')){
        return date;
    }
    if(indexerField.startsWith('n')){
        return date ? new Date(date).valueOf() : 0;
    }
    if(indexerField.startsWith('b')){
        return !!date;
    }
    return undefined;
};
