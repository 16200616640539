import { ContextAware, DetailContext } from "@/app/contexts/detail.context";
import { ExternalContext } from "@/app/contexts/externalContext";
import {getObjectContentsFromPath} from "@/app/helpers/stringpath.helper";
import axios from "axios";
import Vue from "vue";

class RuleEngine {

  public async resolveRuleAsync(context: ContextAware, ruleLine: string, v: Vue): Promise<boolean>{
    if(!context) return false;
    if(!ruleLine || ruleLine === '') return true;

    while(ruleLine.includes('(') && ruleLine.includes(')')){
      ruleLine = await this.resolveParenthesisAsync(context, ruleLine, v);
    }

    let ruleAddition = '&&'
    let rules: string[] = [];
    if(ruleLine.includes('||')){
      ruleAddition = '||';
      rules = ruleLine.split('||');
    }else if(ruleLine.includes('&&')){
      ruleAddition = '&&';
      rules = ruleLine.split('&&');
    }else{
      rules = [ruleLine]
    }

    const results: boolean[] = [];

    for (const rule of rules) {
      if(rule === 'true' || rule === 'false'){
        results.push(rule === 'true');
        continue;
      }

      // 'data.dossier.lot:{eq:test}'
      // 'data.user.roles:{incl:test}'
      let indexOfSplit = rule.indexOf(':');
      while (rule.substring(indexOfSplit,indexOfSplit+2) === ':"'){
        indexOfSplit = rule.indexOf(':',indexOfSplit+1);
      }
      if(indexOfSplit < 0) {
        results.push(false);
        continue;
      }

      const field = rule.substring(0,indexOfSplit);
      const toParse = rule.substring(indexOfSplit+1);

      let op = '';
      let param = '';
      if(toParse.startsWith('{')){
        const indexOfOpsSplit = toParse.indexOf(':');
        if(indexOfOpsSplit < 0) false;
        op = toParse.substring(1, indexOfOpsSplit);
        param = toParse.substring(indexOfOpsSplit + 1);
        param = param.substring(0, param.length-1)
      }else{
        op = 'eq';
        param = toParse;
      }
      if(op === ''){
        results.push(false);
        continue;
      }

      const result = await this.resolveRuleDoAsync(context, field, op, param, v);
      results.push(result);
    }

    if(ruleAddition === '||'){
      return results.includes(true);
    }else if(ruleAddition === '&&'){
      return !results.includes(false);
    }else{
      return false;
    }
  }




  public resolveRule(context: ContextAware, ruleLine: string): boolean{
    if(!context) return false;
    if(!ruleLine || ruleLine === '') return true;

    while(ruleLine.includes('(') && ruleLine.includes(')')){
      ruleLine = this.resolveParenthesis(context, ruleLine);
    }

    let ruleAddition = '&&'
    let rules: string[] = [];
    if(ruleLine.includes('||')){
      ruleAddition = '||';
      rules = ruleLine.split('||');
    }else if(ruleLine.includes('&&')){
      ruleAddition = '&&';
      rules = ruleLine.split('&&');
    }else{
      rules = [ruleLine]
    }

    const results: boolean[] = [];

    rules.forEach(rule => {
      if(rule === 'true' || rule === 'false'){
        results.push(rule === 'true');
        return;
      }

      // 'data.dossier.lot:{eq:test}'
      // 'data.user.roles:{incl:test}'
      const indexOfSplit = rule.indexOf(':');
      if(indexOfSplit < 0) {
        results.push(false);
        return;
      }

      const field = rule.substring(0,indexOfSplit);
      const toParse = rule.substring(indexOfSplit+1);

      let op = '';
      let param = '';
      if(toParse.startsWith('{')){
        const indexOfOpsSplit = toParse.indexOf(':');
        if(indexOfOpsSplit < 0) return false;
        op = toParse.substring(1, indexOfOpsSplit);
        param = toParse.substring(indexOfOpsSplit + 1);
        param = param.substring(0, param.length-1)
      }else{
        op = 'eq';
        param = toParse;
      }
      if(op === ''){
        results.push(false);
        return;
      }

      const result = this.resolveRuleDo(context, field, op, param);
      results.push(result);
    })

    if(ruleAddition === '||'){
      return results.includes(true);
    }else if(ruleAddition === '&&'){
      return !results.includes(false);
    }else{
      return false;
    }
  }

  private async resolveParenthesisAsync(context: ContextAware, ruleLine: string, v: Vue) {
    const regex = /\(([^()]+)\)/
    const results = ruleLine.match(regex);
    if (!results || results.length <= 0) return ruleLine;
    const result = await this.resolveRuleAsync(context, results[1], v)

    const replacedRuleLine = ruleLine.replace('('+ results[1] + ')', result+'');
    return replacedRuleLine
  }

  private resolveParenthesis(context: ContextAware, ruleLine: string) {
    const regex = /\(([^()]+)\)/
    const results = ruleLine.match(regex);
    if (!results || results.length <= 0) return ruleLine;
    const result = this.resolveRule(context, results[1])

    const replacedRuleLine = ruleLine.replace('('+ results[1] + ')', result+'');
    return replacedRuleLine
  }

  private resolveRuleDo(context: ContextAware, field: string, op: string, param: string): boolean {
    let fieldValue = context.resolveDataPath(field);
    switch (op){
      case 'eq':
        if(typeof fieldValue !== "string" && typeof fieldValue !== "number") fieldValue = JSON.stringify(fieldValue);
        if(fieldValue === undefined) fieldValue = 'undefined';
        if(fieldValue === null) fieldValue = 'null';
        //console.log('rule exec', field, fieldValue, op, param, fieldValue === param);
        return fieldValue === param;
      case 'neq':
        if(typeof fieldValue !== "string" && typeof fieldValue !== "number") fieldValue = JSON.stringify(fieldValue);
        if(fieldValue === undefined) fieldValue = 'undefined';
        if(fieldValue === null) fieldValue = 'null';
        //console.log('rule exec', field, fieldValue, op, param, fieldValue !== param);
        return fieldValue !== param;
      case 'incl':
        if(!fieldValue) return false;
        if(Array.isArray(fieldValue)) return Array.from(fieldValue).includes(param);
        if(typeof fieldValue === "object") return Object.keys(fieldValue).includes(param);
        return false;
      default:
        return false;
    }
  }

  private async resolveRuleDoAsync(context: ContextAware, field: string, op: string, param: string, v: Vue): Promise<boolean> {
    console.warn("start resolve", field, op, param);
    let fieldValue;
    if(field.includes('[')){
      fieldValue = await this.resolveFieldValue(field, context, v);
    }else{
      fieldValue = context.resolveDataPath(field);
    }

    switch (op){
      case 'eq':
        if(typeof fieldValue !== "string" && typeof fieldValue !== "number") fieldValue = JSON.stringify(fieldValue);
        if(fieldValue === undefined) fieldValue = 'undefined';
        if(fieldValue === null) fieldValue = 'null';
        //console.log('rule exec', field, fieldValue, op, param, fieldValue === param);
        return fieldValue === param;
      case 'neq':
        if(typeof fieldValue !== "string" && typeof fieldValue !== "number") fieldValue = JSON.stringify(fieldValue);
        if(fieldValue === undefined) fieldValue = 'undefined';
        if(fieldValue === null) fieldValue = 'null';
        //console.log('rule exec', field, fieldValue, op, param, fieldValue !== param);
        return fieldValue !== param;
      case 'incl':
        if(!fieldValue) return false;
        if(Array.isArray(fieldValue)) return Array.from(fieldValue).includes(param);
        if(typeof fieldValue === "object") return Object.keys(fieldValue).includes(param);
        return false;
      default:
        return false;
    }
  }

  private async resolveFieldValue(field: string, context: ContextAware, vue: Vue) : Promise<any> {
    const segements = field.split('|'); // the | is a pipe to next function
    let value;
    for (const segement of segements) {
      const segementParts = segement.split('[');
      const func = segementParts[0];
      const argsString = segementParts[1].substring(0,segementParts[1].length-1);
      const args = argsString.split(';');

      switch (func) {
        case "lookup":
          value = context.resolveDataPath(args[0]);
          break;
        case "url":
          if(args[0].startsWith('api/')) args[0] = vue.$store.state.appConfig.apiBaseUrl + args[0];
          if(args[0].includes('?')){
            const pathParts = args[0].split('?');
            const params = {};
            pathParts[1].split('&').forEach(value1 => {
              const sp = value1.split('=');
              params[sp[0]] = sp[1].replaceAll('"',"'");
            })
            console.warn("url", pathParts[0], params);
            value = await axios.get<any>(pathParts[0], {params: params});
          }else{
            console.warn("url", args[0]);
            value = await axios.get<any>(args[0]);
          }

          break;
        case "select":
          value = getObjectContentsFromPath(args[0], value);
          break;
        case "length":
          if(Array.isArray(value))
            value = ''+value.length;
          break;
        default:
          break;
      }
      const v = value;
    }
    console.warn("current value for", field, value);
    return value;
  }
}

export const ruleEngine = new RuleEngine();
