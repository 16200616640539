import { render, staticRenderFns } from "./ActionTile.vue?vue&type=template&id=1cc27eff&scoped=true&"
import script from "./ActionTile.vue?vue&type=script&lang=ts&"
export * from "./ActionTile.vue?vue&type=script&lang=ts&"
import style0 from "./ActionTile.vue?vue&type=style&index=0&id=1cc27eff&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cc27eff",
  null
  
)

export default component.exports