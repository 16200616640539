







import { Component } from "vue-property-decorator";
import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { Subject, Subscription } from "rxjs";
import { EventDictionary } from "@/app/events/event.dictionary";

@Component({
  components: {},
})
export default class OverviewCellAction extends OverviewCellBase {
  canWork = false;
  working = false;
  eventbusSubcription: Subscription | undefined = undefined;
  mounted(): void {
    this.eventbusSubcription = this.eventbus?.subscribe((val) => {
      if (val.type === "CLEAR_ACTION") {
        this.working = false;
      }
    });
  }

  get color(): string {
    return this.metadata["color"] ? this.metadata["color"] : "primary";
  }

  fireEvent(): void {
    if (this.eventbus) {
      this.working = true;
      this.eventbus.next({
        type: "ACTION",
        metadata: {
          eventId: this.headerId,
          item: this.item,
        },
        item: this.item,
      });
    }
  }

  beforeDestroy(): void {
    if (this.eventbusSubcription) this.eventbusSubcription.unsubscribe();
    this.eventbusSubcription = undefined;
  }
}
