import EventBase from "@/app/events/event-base";
import {DetailContext} from "@/app/contexts/detail.context";
import DossierDetailComponent from "@/app/dynamic-components/details/detail-component-dossier.vue";
import {checkinService} from "@/app/services/checkin.service";
import {DynamicForm} from "@/app/dynamic-components/forms/dynamic-form.model";
import {dossierServiceV2} from "@/app/services/dossier.service";

export default class ShowCheckinEvent extends EventBase {
    async getCallBack(detailsContext: DetailContext, payload: any, rawPayload: any): Promise<any> {
        if (detailsContext.root && detailsContext.root instanceof DossierDetailComponent) {
            detailsContext.root.setModal(payload.dialogDefinitionId, payload.referenceId, detailsContext, payload.data, (form, formData, referenceId) => {
                return this.createCheckin(form, formData, referenceId || '', detailsContext, payload, rawPayload);
            }, (form, formData, referenceId) => Promise.resolve(true));
        }
    }

    async createCheckin(
        form: DynamicForm,
        formData: any,
        referenceId: string,
        detailsContext: DetailContext,
        payload: any,
        rawPayload: any
    ): Promise<boolean> {
        try{
            if(form.formData.referenceIds && Array.isArray(form.formData.referenceIds)){
                const forms: Array<any> = [];
                for (const refId of form.formData.referenceIds) {
                    const formData = JSON.parse(JSON.stringify(form.formData));

                    const newDetailContext = detailsContext?.Clone();
                    if(payload['regardingType'] === 'lot'){
                        const lot = await dossierServiceV2.getLot(payload.referenceId, refId);
                        newDetailContext?.setData("lot", lot);
                    }
                    console.warn('set context lot',payload['linkName'], newDetailContext?.resolvePlaceholders(rawPayload['linkName']));

                    formData['regardingId'] = refId;
                    formData['referenceId'] = refId;
                    formData['regardingType'] = payload['regardingType'];
                    formData['markExecutorsAsBusy'] = true;
                    formData['linkName'] = newDetailContext?.resolvePlaceholders(rawPayload['linkName']);
                    formData['linkPath'] = newDetailContext?.resolvePlaceholders(rawPayload['linkPath']);
                    forms.push(formData);
                }
                await checkinService.createCheckinBulk(form, forms);
                if(detailsContext.root instanceof DossierDetailComponent){
                    await ((detailsContext.root) as DossierDetailComponent).getData();
                }
            }
            return true;
        }catch (e) {
            console.error(e);
            return false;
        }
    }
}
