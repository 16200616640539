







































import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { Component } from "vue-property-decorator";

export type CheckBoxMetaData = {
  color?: string;
};

@Component({})
export default class CheckBoxBase extends BaseDynamicComponent<CheckBoxMetaData> {
  onCheckBoxValueChanged(newValue: boolean): void {
    if (!newValue) {
      newValue = false;
    }
    this.setValueView(newValue);
  }

  protected getDefaultValue(): any {
    return false;
  }
}
