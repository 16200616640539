

















































import { Component, Prop, Vue } from "vue-property-decorator";
import ActionTileContent from "@/app/components/common/ActionTileContent.vue";

@Component({
  components: { ActionTileContent },
})
export default class ActionTile extends Vue {
  @Prop({ default: null })
  image!: string;

  @Prop({ default: "50vw" })
  maxImageWidth!: string;

  @Prop({ default: "50vh" })
  maxImageHeight!: string;

  @Prop({ default: null })
  icon!: string;

  @Prop({ default: null })
  title!: string;

  @Prop({ default: null })
  subtitle!: string;

  @Prop({ default: false })
  droppable!: boolean;

  @Prop({ default: false })
  border!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: true })
  clickable!: boolean;

  isDragover = false;

  onTileClick(): void {
    if (!this.disabled && this.clickable) {
      this.$emit("click");
    }
  }

  stopPropagation(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  draggingStarted(event: Event): void {
    this.stopPropagation(event);
    this.isDragover = true;
  }

  draggingStopped(event: Event): void {
    this.stopPropagation(event);
    this.isDragover = false;
  }

  fileDropped(event: DragEvent): void {
    this.draggingStopped(event);

    if (event && event.dataTransfer) {
      const droppedFiles = event.dataTransfer.files;
      const files =
        droppedFiles instanceof FileList ? [...droppedFiles] : droppedFiles;
      if (files && files.length) {
        this.$emit("filesDropped", files);
      }
    }
  }
}
