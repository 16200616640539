import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";

export interface OverviewEvent {
  type: "ACTION" | "OTHER" | "FETCHED_DATA" | "CLEAR_ACTION" | "FETCHING_NEW_DATA";
  metadata: { [key: string]: any };
  item: any;
}

export class OverviewControl {
  private view: OverviewComponent | undefined;

  public setView(view: OverviewComponent) {
    this.view = view;
  }

  public refresh() {
    if (this.view) this.view.fetchData();
  }

  public sendEvent(overviewEvent: OverviewEvent) {
    if (this.view) this.view.eventbus.next(overviewEvent);
  }
}
