import EventBase from "@/app/events/event-base";
import { DetailContext } from "@/app/contexts/detail.context";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import { Vue } from "vue-property-decorator";
import router from "@/router";
import DossierDetailComponent from "@/app/dynamic-components/details/detail-component-dossier.vue";

export default class ShowDialogEvent extends EventBase {
    async getCallBack(detailsContext: DetailContext, payload: any, rawPayload: any): Promise<any> {
      if (detailsContext.root && detailsContext.root instanceof DossierDetailComponent) {
          detailsContext.root.setModal(payload.dialogDefinitionId, payload.referenceId, detailsContext, payload.data, (form, formData, referenceId) => {
              return Promise.resolve(true);
          }, (form, formData, referenceId) => {
              return Promise.resolve(true);
          });
      }
  }
}
