import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.entry)?_c('div',{staticClass:"mt-2 ml-2"},[_c('div',{staticClass:"readonly-wrapper__label"},[_vm._v(_vm._s(_vm.$t(_vm.entry.name)))]),_c(VBtn,{attrs:{"color":_vm.metadata.colour || 'primary',"small":"","loading":_vm.waitForCompleteValidation,"disabled":!_vm.rules.canRequestValidation},on:{"click":_vm.openValidationRequester}},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.metadata.buttonLabel || 'validationrequest.button')))])]),_c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"persistent":"","eager":true,"width":_vm.metadata.dialogWidth || '800'},model:{value:(_vm.dialogOpen),callback:function ($$v) {_vm.dialogOpen=$$v},expression:"dialogOpen"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t(_vm.metadata.title))+" ")]),_c(VCardText,_vm._l((_vm.validationEntries),function(entry){return _c('div',{key:entry.title},[_c('div',{staticClass:"d-flex justify-start"},[(entry.processing)?_c('loader',{staticClass:"mr-1",attrs:{"size":14}}):_vm._e(),(!entry.processing && entry.check)?_c(VIcon,{staticClass:"mr-1 primary--text"},[_vm._v("mdi-check-circle")]):_vm._e(),(!entry.processing && !entry.check)?_c(VIcon,{staticClass:"mr-1 warning--text"},[_vm._v("mdi-alert-circle")]):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.$t(entry.title)))])],1)])}),0),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.closeValidationRequester}},[_vm._v(" "+_vm._s(_vm.$t("general.close"))+" ")]),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.invalidRequest},on:{"click":_vm.completeValidation}},[_vm._v(" "+_vm._s(_vm.processing ? _vm.$t(_vm.metadata.proccessingLabel) : _vm.$t(_vm.metadata.confirmRequestLabel))+" ")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }