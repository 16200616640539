

















import ActionTile from "@/app/components/common/ActionTile.vue";
import { Component, Vue } from "vue-property-decorator";
import CheckInDossierDialog from "@/components/checkin/CheckInDossierDialog.vue";

@Component({
  components: {
    ActionTile,
    CheckInDossierDialog,
  },
})
export default class CheckInTile extends Vue {
  showDialog = false;

  showCheckinDossierDialog(): void {
    this.showDialog = true;
  }

  closeDialog(): void {
    this.showDialog = false;
  }
}
