









import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { Component, Prop } from "vue-property-decorator";
import ModifyLotsDialog from "@/app/dynamic-components/details/details-components/dialogs/ModifyLotsDialog.vue";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import axios from "@/plugins/axios";
import { RenderItem } from "@/app/Types";
import router from "@/router";
import { i18n } from "@/i18n";
import store from "@/store";

export type StatusUpdaterMetaData = {
  id: string;
  fetchUrl: string;
  patchUrl: string;
  status: string;
  translated: string;
  colour: "black" | "red" | "primary";
};

@Component({})
export default class DetailComponentStatusUpdater extends DetailComponentBase<StatusUpdaterMetaData> {
  @Prop({ default: null })
  text!: string;

  updating = false;

  get translatedValue() {
    if (this.metadata.translated) return this.$t(this.metadata.translated);
    return this.$te(this.text) ? this.$t(this.text) : this.text;
  }

  async updateStatus(): Promise<void> {
    this.resolveMetadataUrls();

    if (!this.metadata.fetchUrl || !this.metadata.patchUrl) {
      this.$toast.error(this.$t("general.update_status.error.missing_urls"));
      return;
    }

    this.updating = true;

    let object = {};
    try {
      object = (await axios.get(`${this.metadata.fetchUrl}${this.metadata.id}`)).data;
      object["status"] = this.metadata.status;
    }catch (ex){
      this.updating = false;
      return;
    }

    axios
      .put(`${this.metadata.patchUrl}${this.metadata.id}`, object)
      .then(async () => {
        const p = this.$route.path;
        const q = this.$route.query;
        this.$toast.success(this.$t("lot.updated"));
        this.updating = false;

        await this.$router.push({path: '/placeholder', replace: true});
        setTimeout(async () => {
          await this.$router.push({path: p, query: q, replace: true});
        }, 100);
      })
      .catch(() => {
        this.$toast.error(this.$t("general.error_occurred"));
        this.updating = false;
      });
  }

  private resolveMetadataUrls() {
    if(this.metadata.fetchUrl && this.metadata.fetchUrl.startsWith('api/')){
      this.metadata.fetchUrl = store.state.appConfig.apiBaseUrl + this.metadata.fetchUrl
    }
    if(this.metadata.patchUrl && this.metadata.patchUrl.startsWith('api/')){
      this.metadata.patchUrl = store.state.appConfig.apiBaseUrl + this.metadata.patchUrl
    }
  }
}
