


















import { Component } from "vue-property-decorator";
import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { getObjectContentsFromPath } from "@/app/helpers/stringpath.helper";

@Component({
  components: {},
})
export default class OverviewCellList extends OverviewCellBase {
  mounted(): void {
    const i = 0;
  }

  list: string[] = [];

  protected PostResolveValue() {
    if (this.metadata) {
      if ((this.metadata?.translateList || this.metadata?.translateTree) && this.value) {
        //entry in dataresolver
        let path = this.metadata?.translateList || this.metadata?.translateTree;
        if (path.startsWith("data.")) {
          path = path.replace("data.", "");
        }
        let resolvedItems = getObjectContentsFromPath(path, this.additionalMetadata
        );
        if(!resolvedItems){
          resolvedItems = this.overviewContext.resolveDataPath(path);
        }

        if(resolvedItems && !Array.isArray(resolvedItems) && typeof resolvedItems === 'object'){
          const newResolvedItems: Array<any> = [];
          Object.entries(resolvedItems).forEach(value => {
            console.warn(value[1]);
            if(value[1] && typeof value[1] === "object"){
              value[1]['key'] = value[0];
              newResolvedItems.push(value[1]);
            }
          });
          resolvedItems = newResolvedItems;
        }

        //flatmap tree
        if(this.metadata?.translateTree && this.metadata?.translateTreeItemChild){
          const newList = []
          console.warn(resolvedItems);
          this.flatmapTreeForTranslate(newList, resolvedItems);
          console.warn(newList);
          resolvedItems = newList;
        }

        if (Array.isArray(resolvedItems)) {
          this.list = resolvedItems
            .filter((it) => {
              return Array.from(this.value).includes(
                it[this.metadata.itemValue]
              );
            })
            .map((value) => {
              return value[this.metadata.itemText];
            });
          if (this.metadata?.translated || this.additionalMetadata?.translated) {
            this.list = this.list.map((v) => this.$tc(v));
          }
        } else {
          this.list = this.value;
        }
      } else {
        this.list = this.value;
      }
    }
  }

  get iconColor(): string {
    return this.metadata["color"] ? this.metadata["color"] : "primary";
  }

  private flatmapTreeForTranslate(translateList: Array<any>, item: any) {
    if(item && !Array.isArray(item) && typeof item === 'object'){
      Object.entries(item).forEach(iter => {
        if(iter[1] && typeof iter[1] === 'object'){
          const ite = iter[1];
          ite['key'] = iter[0];
          translateList.push(ite);
          if(ite[this.metadata.translateTreeItemChild]){
            this.flatmapTreeForTranslate(translateList, ite[this.metadata.translateTreeItemChild]);
          }
        }
      })
    }else if(Array.isArray(item)){
      item.forEach(iter => {
        translateList.push(iter);
        if(iter[this.metadata.translateTreeItemChild]){
          this.flatmapTreeForTranslate(translateList, iter[this.metadata.translateTreeItemChild]);
        }
      })
    }

  }
}
