import EventBase from "@/app/events/event-base";
import { DetailContext } from "@/app/contexts/detail.context";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import { Vue } from "vue-property-decorator";
import router from "@/router";

export default class CancelDossierEvent extends EventBase {
  async getCallBack(detailsContext: DetailContext, payload: any, rawPayload: any): Promise<any> {
    dossierServiceV2
      .updateDossierStatus(detailsContext, "Cancelled")
      .then(() => {
        Vue.$toast.success("Successfully saved.");
        router.back();
      })
      .catch(() => {
        Vue.$toast.error("Something went wrong.");
      });
  }
}
