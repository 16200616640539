




































































































import { Component, Prop, Vue } from "vue-property-decorator";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import Loader from "@/components/common/Loader.vue";
import PageConfig from "@/contracts/generic/PageConfig";
import SortConfig from "@/contracts/generic/SortConfig";
import DocumentFilterConfig from "@/contracts/document/DocumentFilterConfig";
import { DocumentDto } from "@/contracts/document/DocumentsDto";
import DocumentsTable from "@/components/common/documents/DocumentsTable.vue";
import UploadDocumentTile from "@/components/documents/upload/UploadDocumentTile.vue";
import ActionTile from "@/app/components/common/ActionTile.vue";
import DocumentsFilters from "@/components/common/documents/DocumentsFilters.vue";
import { dossierService } from "@/services/dossier.service";
import { Routes } from "@/router";
import RelatedDocumentsDetailsActions from "@/components/dossiers/documents/RelatedDocumentsDetailsActions.vue";
import RelatedDocumentsActions from "@/components/dossiers/documents/RelatedDocumentsActions.vue";
import { documentTypes } from "@/constants/DocumentTypes";
import CreateDocumentDto from "@/contracts/document/CreateDocumentDto";
import { i18n } from "@/i18n";
import { documentMapper } from "@/mappers/document.mapper";
import { roleBasedActionsService } from "@/services/role-based-actions.service";
import { TableColumns } from "@/constants/TableColumns";
import PageHeader from "@/components/layout/PageHeader.vue";
import { documentService } from "@/services/document.service";
import { AxiosPromise } from "axios";

@Component({
  components: {
    RelatedDocumentsActions,
    RelatedDocumentsDetailsActions,
    DocumentsTable,
    DocumentsFilters,
    AcceptPoliciesInterceptor,
    AuthenticationInterceptor,
    UploadDocumentTile,
    ActionTile,
    Loader,
    PageHeader,
  },
})
export default class RelatedDocumentsView extends Vue {
  @Prop({ default: null })
  dossierId!: string;

  initialLoading = false;
  loading = false;
  documentType = documentTypes.RELATED;

  canEditDocuments = false;

  dossierActivityId: string | null = null;

  pageConfig: PageConfig = {
    page: 1,
    pageSize: 10,
  };

  sortConfig: SortConfig = {
    sortBy: TableColumns.DATE,
    sortDesc: true,
  };

  filterConfig: DocumentFilterConfig = {
    categories: [],
    types: [],
    activities: [],
    uploadedBy: [],
    organizations: [],
  };

  amountOfDocuments = 0;
  initialFilterConfig = JSON.stringify(this.filterConfig);
  documents: DocumentDto[] = [];

  get userIsAuthorizedToDeleteDossierDocument(): boolean {
    return roleBasedActionsService.userIsAuthorizedToDeleteDossierDocument();
  }

  get userIsAuthorizedToCreateDossierDocument(): boolean {
    return roleBasedActionsService.userIsAuthorizedToCreateDossierDocument();
  }

  mounted(): void {
    this.loadData();
  }

  loadData(): void {
    this.checkIfCanEditDocuments();
    this.fetchDossierActivity();
    this.fetchDocuments(true);
  }

  checkIfCanEditDocuments(): void {
    dossierService.canEditDossier(this.dossierId).then((canEditDossier) => {
      this.canEditDocuments = canEditDossier; // if the dossier cannot be edited, the related documents cannot be edited
    });
  }

  fetchDossierActivity(): void {
    dossierService
      .getDossier(this.dossierId)
      .then((data) => (this.dossierActivityId = data.activityDomains.activity));
  }

  fetchDocuments(initialFetch = false): void {
    if (initialFetch) {
      this.initialLoading = true;
    }

    this.loading = true;

    dossierService
      .getRelatedDocuments(
        this.dossierId,
        this.pageConfig,
        this.sortConfig,
        this.filterConfig
      )
      .then((data) => {
        if (data) {
          if (initialFetch) {
            this.amountOfDocuments = data.rowCount;
          }

          if (data.results) {
            this.documents = data.results;
          }
        }
      })
      .finally(() => {
        if (initialFetch) {
          this.initialLoading = false;
        }

        this.loading = false;
      });
  }

  pageConfigUpdated(pageConfig: PageConfig): void {
    this.pageConfig = pageConfig;
    this.fetchDocuments();
  }

  sortConfigUpdated(sortConfig: SortConfig): void {
    this.sortConfig = sortConfig;
    this.fetchDocuments();
  }

  filterConfigUpdated(filterConfig: DocumentFilterConfig): void {
    this.filterConfig = filterConfig;
    this.fetchDocuments();
  }

  navigateToDossierDetails(): void {
    this.$router.push({
      name: Routes.DossierDetails,
      params: {
        id: this.dossierId,
      },
    });
  }

  documentAdded(document: CreateDocumentDto | null): void {
    if (document) {
      this.loading = true;
      document.dossier = this.dossierId;
      let files = document.files;
      document.files = null;
      let promises: AxiosPromise<void>[] = [];

      files?.forEach((value) => {
        document.files = [value];
        let formData = documentMapper.mapCreateDocumentDtoToFormData(document);

        if (value instanceof File) {
          promises.push(documentService.addDocument(formData));
        }
      });

      Promise.all(promises)
        .then(() => {
          this.filterConfig = JSON.parse(this.initialFilterConfig);
          this.fetchDocuments(true);
          Vue.$toast.success(i18n.t("my-documents.document-added"));
        })
        .catch(() => {
          Vue.$toast.error(i18n.t("my-documents.document-add-failed"));
        })
        .finally(() => (this.loading = false));
    }
  }
}
