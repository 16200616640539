import OverviewCellList from "@/app/dynamic-components/overviews/cells/components/overview-cell-list.vue";

export default class OverviewCellLookup {
  public getComponent(type: string): string {
    switch (type) {
      case "Share":
        return "OverviewCellShare";
      case "Download":
        return "OverviewCellDownload";
      case "Action":
        return "OverviewCellAction";
      case "State":
        return "OverviewCellState";
      case "Date":
        return "OverviewCellDate";
      case "StepCount":
        return "OverviewCellStepcount";
      case "ExchangeAction":
        return "OverviewCellExchangeAction";
      case "Person":
        return "OverviewCellPerson";
      case "Organisation":
        return "OverviewCellOrganisation";
      case "List":
        return "OverviewCellList";
      case "Address":
        return "OverviewCellAddress";
      case "Breadcrumb":
        return "OverviewCellBreadcrumb";
      case "Link":
        return "OverviewCellLink";
      case "OverviewRef":
        return "OverviewCellOverviewRef";
      case "Planning":
        return "OverviewCellPlanning";
      case "OrgAndPerson":
        return "OverviewCellOrganisationAndPersons";
      case "ContactDetails":
        return "OverviewCellContactdetails"
      default:
        return "OverviewCellText";
    }
  }
}

export const overviewCellLookup = new OverviewCellLookup();
