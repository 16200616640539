

















import { Component } from "vue-property-decorator";
import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { WidgetMetaData } from "@/app/dynamic-components/details/details-components/widgets/widget-metaData.model";
import { Overview, OverviewPage } from "@/app/models/overview/overview.model";
import { deepMergeObjects } from "@/app/helpers/stringpath.helper";
import router, { Routes } from "@/router";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";

export interface OverviewWidgetMetaData extends WidgetMetaData {
  overviewDefinitionId: string;
  entityType: string;
  tableStyle: string;
  path: string;
  itemDisplayLimit: number
}

@Component({
  components: { OverviewComponent }
})
export default class WidgetComponentOverview extends DetailComponentBase<OverviewWidgetMetaData> {
  mapToOverviewData: (data: Overview) => Overview = (data: Overview) => {
    return {
      _embedded: {
        results: data._embedded.results.map((result) => {
          return {
            ...result,
            ...{
              data: deepMergeObjects(result.data, result["dynamic_Properties"]),
              id: result.id,
              dossierDefinitionId: result.dossierDefinitionId,
              createdOn: result.createdOn,
              lastModifiedOn: result.lastModifiedOn,
            },
          };
        }),
      },
      _filter: data._filter,
      _page: this.setItemDisplayLimit(data._page),
      _sort: data._sort,
    };
  };

  setItemDisplayLimit(page: OverviewPage): OverviewPage {
    page.size = this.metadata.itemDisplayLimit;
    return page;
  }

  dossierSelected(item: { item: any; index: number }) {
    router.push({
      name: Routes.DossierDetailsV2,
      params: {
        id: item.item["id"],
      },
    });
  }
}
