













import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import { Component } from "vue-property-decorator";
import { WidgetMetaData } from "@/app/dynamic-components/details/details-components/widgets/widget-metaData.model";
import ActionTile from "@/app/components/common/ActionTile.vue";

@Component({
})
export default class WidgetComponentVertical extends DetailComponentBase<WidgetMetaData> {

}
