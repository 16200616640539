import EventBase from "@/app/events/event-base";
import { DetailContext } from "@/app/contexts/detail.context";

export default class DeleteDossierEvent extends EventBase {
  constructor() {
    super();
  }

  async getCallBack(detailsContext: DetailContext, payload: any, rawPayload: any): Promise<any> {
    console.log("test delete");
  }
}
