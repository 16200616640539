















import { Component, Prop, Vue } from "vue-property-decorator";
import ActionTile from "@/app/components/common/ActionTile.vue";

@Component({
  components: {
    ActionTile,
  },
})
export default class AddLotTile extends Vue {
  @Prop({ default: null })
  icon!: string;

  @Prop({ default: "50vw" })
  maxImageWidth!: string;

  @Prop({ default: true })
  canAddLot!: boolean;

  addLot(): void {
    this.$emit("addLot");
  }
}
