import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { RenderType } from "@/app/Types";
import { Subject, Subscription } from "rxjs";
import {
  DynamicFormEntry,
  FormEvent,
} from "@/app/dynamic-components/forms/dynamic-form.model";
import { ValidationError } from "@/app/dynamic-components/forms/form-validations/form-validator.base";
import { getObjectContentsFromPath } from "@/app/helpers/stringpath.helper";
import { organisationService } from "@/app/services/organisation.service";

/**
 * This is a super class for all base components that are used in the form builder.
 * It takes props that should be available in all base components.
 * Every base component should inherit from this super class.
 */
@Component({})
export default class BaseDynamicComponent<T> extends Vue {
  @Prop({
    default: () => {
      return new DynamicFormEntry(
        "",
        "",
        RenderType.TEXTFIELD,
        "readonly",
        "",
        "",
        {},
        undefined,
        undefined
      );
    },
  })
  entry!: DynamicFormEntry;

  value: any = null;

  errors: ValidationError[] = [];
  errorMessages: string[] = [];

  private calculatedValueSubscription: Subscription | null = null;

  @Watch("entry", { immediate: true })
  onEntryChanged(newValue: DynamicFormEntry, oldValue: DynamicFormEntry) {
    this.detachCalculatedValue();
    if (newValue && newValue.computedValue && newValue.computedValue !== "") {
      this.attatchCalculatedValue();
    }
    this.postEntityChanged(newValue);
  }

  postEntityChanged(newValue: DynamicFormEntry) {
    return;
  }

  @Watch("value", { immediate: true, deep: true })
  onValueChanged(newValue: any, oldValue: any) {
    if (oldValue === undefined && newValue === null) return;
    if (oldValue === null && newValue === this.getDefaultValue()) return;
    // console.warn("value being set:", newValue, oldValue, this.entry.name);
    if (this.entry) {
      this.setValueEntry(newValue);
    }
  }

  /*** Don't override this method, override postMounted instead ***/
  mounted(): void {
    if (this.entry) {
      this.entry.bindView(this);
      const defaultValue = this.getDefaultValue();
      if (defaultValue != this.value) {
        this.entry.value = defaultValue;
        this.value = defaultValue;
      }
    }
    if (this.entry?.value) {
      this.setValueView(this.entry?.value);
    }
    this.postMounted();
  }

  postMounted() {
    return;
  }

  beforeDestroy(): void {
    return;
  }
  /*** Don't override this method, override postSetValueView instead ***/
  setValueView(value: any) {
    this.value = value;
    this.postSetValueView(value);
  }

  postSetValueView(value: any) {
    return;
  }
  /*** Don't override this method, override postSetValueEntry instead ***/
  setValueEntry(value: any, immidiate?: boolean) {
    this.entry.setValue(value, {
      immediate: immidiate || false,
      emitEvents: true,
    });
    this.postSetValueEntry(value);
  }

  postSetValueEntry(value: any) {
    return;
  }

  setErrors(result: ValidationError[]) {
    if (!result) result = [];
    this.errors = result;
    this.errorMessages = result.map((value1) => value1.description);
    this.postSetErrors(result);
  }

  postSetErrors(errors: ValidationError[]) {
    return;
  }

  private detachCalculatedValue() {
    if (this.calculatedValueSubscription)
      this.calculatedValueSubscription.unsubscribe();
    this.calculatedValueSubscription = null;
  }

  private attatchCalculatedValue() {
    this.calculatedValueSubscription =
      this.entry.root?.formDataEmittor.subscribe(async (formValue) => {
        const entry = this.entry;
        if (entry && entry.computedValue && entry.computedValue != "") {
          let result = await this.entry.root?.resolvePlaceholders(
            entry.computedValue
          );
          result = await this.replaceFunctions(result);
          this.setValueView(result);
        }
      }) || null;
  }

  private async replaceFunctions(computedValue: string) {
    let result = computedValue;
    result = result + "";
    const calculatedReplaceRegex = /([a-zA-Z0-9_-]+)\(([^)]*)\)/g;
    const replaceRegex = calculatedReplaceRegex.exec(computedValue);
    if (replaceRegex && replaceRegex.length === 3) {
      const op = replaceRegex[1];
      const val = replaceRegex[2];
      let resolveValue = replaceRegex[2];
      //console.warn("regex match", op, val);
      switch (op) {
        case "organisation":
          resolveValue = await this.resolveOrg(val);
          break;
        default:
          break;
      }
      result = result.replace(replaceRegex[0], resolveValue);
    } else {
      //console.warn("regex match none", computedValue);
    }
    return result;
  }

  private async resolveOrg(id: string): Promise<string> {
    const getOrgs = await organisationService.getOrganisationsByIds([id]);
    return getOrgs && getOrgs.length > 0 && getOrgs[0].name
      ? getOrgs[0].name
      : "";
  }

  public get displayMode(): string | undefined {
    if (!this.entry?.displayMode) return "";
    const result = this.entry.resolvedDisplayMode;
    return result;
  }

  async beforeSave(): Promise<boolean> {
    return true;
  }

  async afterSave(sumbitData: any): Promise<boolean> {
    return true;
  }

  async getEmbeddedErrors(): Promise<ValidationError[]> {
    return [];
  }

  protected getDefaultValue(): any {
    return null;
  }

  public resetEmbeddedValidation() {
    return;
  }

  public fillIndexerValue(indexerField: string, indexerSelector): any {
    return this.value;
  }
}
