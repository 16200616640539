































































import {Component, Prop, Vue} from "vue-property-decorator";
import {validationRules} from "@/constants/ValidationRules";
import AutocompleteWithErrors from "@/app/components/AutocompleteWithErrors.vue";
import {ValidationError} from "@/app/dynamic-components/forms/form-validations/form-validator.base";
import DocumentTile from "@/components/documents/DocumentTile.vue";
import {VFileInput} from "vuetify/lib/components";
import ActionTile from "@/app/components/common/ActionTile.vue";
import UploadDocumentTile from "@/components/documents/upload/UploadDocumentTile.vue";

@Component({
  components: {
    UploadDocumentTile,
    ActionTile,
    DocumentTile,
    AutocompleteWithErrors,
  },
})
export default class DragAndDropFileInput extends Vue {
  @Prop({default: false})
  required!: boolean;

  @Prop({default: false})
  disabled!: boolean;

  @Prop({default: () => []})
  errors!: ValidationError[];

  @Prop({default: () => []})
  errorMessages!: string[];

  @Prop({default: []})
  value!: File[];

  @Prop({default: false})
  accept!: string;

  @Prop({default: false})
  multiple!: boolean;

  @Prop({default: "50vw"})
  maxImageWidth!: string;

  @Prop({default: "50vh"})
  maxImageHeight!: string;

  rules: unknown[] = [];

  mounted(): void {
    if (this.required) {
      this.rules.push(validationRules.required);
    }
  }

  update(newValue: File[]): void {
    if (
        Array.isArray(newValue) &&
        newValue.length !== 0 &&
        Array.isArray(this.value)
    ) {
      this.value.forEach((file) => {
        newValue.push(file);
      });
    }
    this.$emit("input", newValue);
  }

  deleteDocument(index: number): void {
    this.value.splice(index, 1);
    this.$emit("input", this.value);
  }

  triggerFileInput(): void {
    const fileInputElement = (
        this.$refs.fileInput as InstanceType<typeof VFileInput>
    ).$refs.input as HTMLInputElement;

    fileInputElement.click();
  }
}
