




























import { Component, Watch } from "vue-property-decorator";
import Autocomplete from "@/components/common/Autocomplete.vue";
import BaseDynamicComponent from "../form-component.base";
import { DynamicFormEntry } from "@/app/dynamic-components/forms/dynamic-form.model";
import AutocompleteWithErrors from "@/app/components/AutocompleteWithErrors.vue";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import axios from "@/plugins/axios";
import { Overview } from "@/app/models/overview/overview.model";
import { getObjectContentsFromPath } from "@/app/helpers/stringpath.helper";

export type SelectFromOverviewMetaData = {
  path: string;
  disableSearchOnEntry: boolean;
  itemText: string;
  itemValue: string;

  chips: boolean;
  clearable: boolean;
  dense: boolean;
  multiple: boolean;

  deletableChips: boolean;
  translate: boolean;
  autoSetOneItem: boolean;
  sortAlphabetically: boolean;
};
@Component({
  components: {
    AutocompleteWithErrors,
    Autocomplete,
  },
})
export default class DynamicSelectFromOverview extends BaseDynamicComponent<SelectFromOverviewMetaData> {
  selectedList: string[] | string = [];
  list: any[] = [];
  setNewPathSubject: Subject<string> = new Subject();
  setNewPathSubscription: Subscription | null = null;
  formDataChangedSubscription: Subscription | null = null;
  path = "";
  pathSearchString = "";
  public refreshingList = false;

  displayList(): string {
    return this.list
      .filter((e) => {
        if (this.entry?.metadata?.itemValue) {
          return this.selectedList.includes(e[this.entry?.metadata?.itemValue]);
        } else {
          return this.selectedList.includes(e);
        }
      })
      .map((e) => {
        if (this.entry?.metadata?.itemText) {
          return e['label'];
        } else {
          return e;
        }
      })
      .join(", ");
  }

  postEntityChanged(entry: DynamicFormEntry) {
    if (entry && entry.metadata && entry.root) {
      if (this.setNewPathSubscription) this.setNewPathSubscription.unsubscribe();
      this.setNewPathSubscription = this.setNewPathSubject
          .pipe(debounceTime(200))
          .subscribe((v) => {
            this.setNewPath(v);
          });

      let path = entry.metadata.path;
      path = entry.root.resolvePlaceholders(path);
      this.setNewPathSubject.next(path);

      if (this.formDataChangedSubscription)
        this.formDataChangedSubscription.unsubscribe();
      this.formDataChangedSubscription = entry.root.formDataEmittor
          .pipe(debounceTime(300))
          .subscribe((val) => {
            const newPath = entry.root?.resolvePlaceholders(entry.metadata.path);
            if (newPath !== this.path) {
              this.setNewPathSubject.next(newPath);
            }
          });
    }
  }

  public onIntermediateText(val: string) {
    if (this.entry?.metadata?.disableSearchOnEntry) return;
    this.pathSearchString = val;
    this.setNewPathSubject.next(this.path);
  }

  @Watch("selectedList")
  onPersonsChanged() {
    this.setValueView(this.selectedList);
  }
  postSetValueView(value: any) {
    console.warn('set val multiselect', JSON.stringify(value));
    if (!value) {
      this.selectedList = [];
    } else {
      if (JSON.stringify(value) !== JSON.stringify(this.selectedList)) {
        if(Array.isArray(value)){
          this.selectedList = value;
        }else{
          this.selectedList = [value];
        }
      }
    }
  }

  beforeDestroy(): void {
    if (this.formDataChangedSubscription)
      this.formDataChangedSubscription.unsubscribe();
    this.formDataChangedSubscription = null;
    if (this.setNewPathSubscription) this.setNewPathSubscription.unsubscribe();
    this.setNewPathSubscription = null;
  }

  private setNewPath(newPath: string) {
    if (!newPath) return;
    if (newPath.includes("$nan")) return;

    this.path = newPath;
    if (
      this.pathSearchString &&
      this.pathSearchString !== "" &&
      this.pathSearchString !== "nan"
    ) {
      newPath += this.path.includes("?")
        ? `&q=${this.pathSearchString}`
        : `?q=${this.pathSearchString}`;
    }
    if(newPath.startsWith('api')){
      newPath = this.$store.state.appConfig.apiBaseUrl + newPath;
    }

    this.refreshingList = true;

    return axios
      .get<Overview>(newPath)
      .then((response) => {
        if (
          response.data &&
          response.data._embedded &&
          response.data._embedded["results"]
        ) {
          this.list = Array.from(response.data._embedded["results"]).map(
            (value) => {
              return {
                id: getObjectContentsFromPath(
                  this.entry.metadata.itemValue,
                  value
                ),
                label: getObjectContentsFromPath(
                  this.entry.metadata.itemText,
                  value
                ),
              };
            }
          );
        } else {
          this.list = [];
        }
        this.refreshingList = false;
      })
      .catch((reason) => {
        this.refreshingList = false;
      });
  }
}
