import {
  AddLinkProduct,
  CreateProductRequest,
  ProductModel,
  UpdateProductRequest,
} from "@/app/models/product/product.model";
import {
  DynamicForm,
  DynamicFormEntry, IndexList,
} from "@/app/dynamic-components/forms/dynamic-form.model";
import { Overview } from "@/app/models/overview/overview.model";
import axios from "@/plugins/axios";
import { LotModel } from "@/app/models/lot/lot.model";
import { convertTypeToApiType } from "@/app/helpers/api.helper";
import store from "@/store";

class ProductService {
  async getProducts(): Promise<Overview> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/products?page=0&pageSize=100&sort=`;
    return axios.get<Overview>(url).then((response) => {
      if (response?.data?._embedded?.results) {
        response.data._embedded.results = Array.from(
          response.data._embedded.results
        ).map((value) => this.convertGetProductToProduct(value));
      }
      return response.data;
    });
  }

  async getProduct(productId: string): Promise<ProductModel> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/products/${productId}`;
    return axios.get(url).then((response) => {
      return this.convertGetProductToProduct(response.data);
    });
  }

  async createProduct(form: DynamicForm, create: any): Promise<string> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/products`;
    const createRequest = this.convertFormDataToProductCreateRequest(
      form,
      create
    );
    createRequest.status = "ACTIVE";
    return axios.post(url, createRequest).then((response) => {
      return response.data;
    });
  }

  async updateProduct(
    form: DynamicForm,
    productId: string,
    update: any
  ): Promise<ProductModel> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/products/${productId}`;
    const updateRequest = this.convertFormDataToProductUpdateRequest(
      form,
      update
    );
    updateRequest.status = "ACTIVE";
    return axios.put(url, updateRequest).then((response) => {
      return this.convertGetProductToProduct(response.data);
    });
  }

  async deleteProduct(
    form: DynamicForm,
    productId: string,
    update: any
  ): Promise<ProductModel> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/products/${productId}`;
    const updateRequest = this.convertFormDataToProductUpdateRequest(
      form,
      update
    );
    updateRequest.status = "DISCONTINUED";
    return axios.put(url, updateRequest).then((response) => {
      return this.convertGetProductToProduct(response.data);
    });
  }

  async linkProduct(
    productId: string,
    referenceId: string,
    type: string
  ): Promise<boolean> {
    type = convertTypeToApiType(type);
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/products/${productId}/links`;
    const link: AddLinkProduct = {
      referenceId: referenceId,
      regardingId: referenceId,
      regardingType: type,
    };

    return axios.post(url, link).then((response) => {
      return true;
    });
  }

  async unlinkProduct(productId: string, linkId: string) {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/products/${productId}/links/${linkId}`;
    return axios.delete(url).then((response) => {
      return true;
    });
  }

  public convertGetProductToProduct(apiResponse: any): ProductModel {
    apiResponse = { ...apiResponse, ...apiResponse.dynamic_Properties };
    apiResponse.status = apiResponse?.status?.toUpperCase();
    return apiResponse;
  }

  private convertFormDataToProductCreateRequest(
    form: DynamicForm,
    formdata: any
  ): CreateProductRequest {
    const mappedValues = DynamicFormEntry.SEPERATE_FIELDS(formdata, form);
    const result = mappedValues.data;
    result.dynamic_Properties = mappedValues.dynamicProperties;

    const indexers = new IndexList();
    form.resolveIndexers(indexers);
    result['indexers'] = indexers;

    return result;
  }

  private convertFormDataToProductUpdateRequest(
    form: DynamicForm,
    formdata: any
  ): UpdateProductRequest {
    const mappedValues = DynamicFormEntry.SEPERATE_FIELDS(formdata, form);
    const result = mappedValues.data;
    result.dynamic_Properties = mappedValues.dynamicProperties;

    const indexers = new IndexList();
    form.resolveIndexers(indexers);
    result['indexers'] = indexers;

    return result;
  }
}

export const productService = new ProductService();
