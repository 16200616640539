






































import { Component } from "vue-property-decorator";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import Loader from "@/components/common/Loader.vue";
import ActionTile from "@/app/components/common/ActionTile.vue";
import MyDocumentsView from "@/views/documents/MyDocumentsView.vue";
import FormDialogComponent from "@/app/components/dialog/form-dialog-component.vue";
import {
  FormDialogControl,
  FormDialogModel,
} from "@/app/components/dialog/form-dialog.model";
import { ExternalContext } from "@/app/contexts/externalContext";
import { RenderType } from "@/app/Types";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import {
  OverviewControl,
  OverviewEvent,
} from "@/app/dynamic-components/overviews/overiew.model";

@Component({
  components: {
    FormDialogComponent,
    MyDocumentsView,
    ActionTile,
    Loader,
    AuthenticationInterceptor,
    AcceptPoliciesInterceptor,
    PageHeader,
    OverviewComponent,
  },
})
export default class PlanningsOverviewView extends ActivityDependendViewBase {
  additionalFilters: { [key: string]: string } = {};
  additionalMetadata: { [key: string]: any } = {};

  async onPageReady(): Promise<any> {
    return;
  }

  showUpdateDialog(item: { item: any; index: number }) {
    if(item?.item?.responsableOrganisationId !== this.$store.state.user.organizationId) return;
    this.editFormDialogControl.show(item.item.id, { planning: item.item.id });
  }

  private editFormDialogControl!: FormDialogControl;
  recieveEditPlanningControl(control: FormDialogControl) {
    this.editFormDialogControl = control;
    this.editFormDialogControl.submitChanges = this.updatePlanning;
    this.editFormDialogControl.afterSubmitChanges = this.refreshTable;
  }

  updatePlanning(formData: any): Promise<boolean> {
    // done in form element
    return Promise.resolve(true);
  }

  refreshTable(
    form: DynamicForm,
    formData: any,
    referenceId?: string
  ): Promise<any> {
    this.overviewControl?.refresh();
    return Promise.resolve(true);
  }

  public editPressed(overviewEvent: OverviewEvent) {
    this.showUpdateDialog({ item: overviewEvent.item, index: 0 });
    this.overviewControl?.sendEvent({
      type: "CLEAR_ACTION",
      item: null,
      metadata: {},
    });
  }

  private overviewControl: OverviewControl | null = null;
  public recieveOverviewControl(overviewControl: OverviewControl) {
    this.overviewControl = overviewControl;
  }
}
