import {
  FormValidator,
  FormValidatorBase,
} from "@/app/dynamic-components/forms/form-validations/form-validator.base";
import {DynamicFormEntry} from "@/app/dynamic-components/forms/dynamic-form.model";

export default class MinSizeFormValidator extends FormValidatorBase {
  constructor() {
    super("minSize", "At least " + 0 + " entries are required");
  }

  isValid(formData: any, entry: DynamicFormEntry): boolean {
    if (formData === undefined || formData === null) return false;
    if (Array.isArray(formData)) {
      return Array.from(formData).length >= this.params[0];
    }
    if (typeof formData === 'object') {
      return Object.entries(formData).filter(value => !!value[1]).length > 0;
    }
    return false;
  }

  constructImpl(params: any[]): FormValidator {
    const validator = new MinSizeFormValidator();
    const amount = Number.parseInt(params[0]);
    validator.params = params;
    validator.templatedDescription = amount === 1 ? "At least 1 entry is required" : "At least " + params[0] + " entries are required";


    return validator;
  }
}
