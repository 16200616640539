import { ContextAware, DetailContext } from "@/app/contexts/detail.context";
import { getObjectContentsFromPath } from "@/app/helpers/stringpath.helper";
import {Store} from "vuex";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import Vue from "vue";

export class OverviewContext implements ContextAware{
  data: { [key: string]: any } = {};

  public setData(key: string, rule: any): OverviewContext {
    this.data[key] = rule;
    return this;
  }

  public resolvePlaceholders(data: any): any {
    if (typeof data === "string") {
      return this.resolvePlaceholdersString(data);
    }
    return JSON.parse(this.resolvePlaceholdersString(JSON.stringify(data)));
  }

  private resolvePlaceholdersString(str: any): any {
    const calculatedReplaceRegex = /\${([^$}]*)}/;

    let replaceRegex;
    let c = 0;
    while ((replaceRegex = calculatedReplaceRegex.exec(str)) && c < 1000) {
      const value = this.resolveDataPath(replaceRegex[1]);
      str = str.replaceAll(replaceRegex[0], value ? value : "$nan");
      //console.log("found regex", JSON.stringify(replaceRegex), value, str);
      c++;
    }
    return str;
  }

  public resolveDataPath(dataPath: string): any {
    if (dataPath.startsWith("data.")) dataPath = dataPath.replace("data.", "");
    return getObjectContentsFromPath(dataPath, this.data);
  }

  public inherit( c: ContextAware ): OverviewContext {
    if (!c || !c.getData()) return this;
    Object.entries(c.getData()).forEach((value) => {
      this.data[value[0]] = value[1];
    });
    return this;
  }

  getData(): { [p: string]: any } {
    return this.data
  }
}
