








import { Component } from "vue-property-decorator";
import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";

@Component({
  components: {},
})
export default class OverviewCellContactdetails extends OverviewCellBase {

  displayValue(): string{
    if (this.value === null || this.value === undefined || typeof this.value !== 'object') return "-";

    return (this.value?.firstName || '') + ' '+ (this.value?.lastName || '');
  }

  displayValueEmail(): string | null{
    if (this.value === null || this.value === undefined || typeof this.value !== 'object') return null;

    if(this.metadata.showEmail){
      return this.value?.email || ''
    }
    return null;
  }

  displayValuePhone(): string | null{
    if (this.value === null || this.value === undefined || typeof this.value !== 'object') return null;

    if(this.metadata.telephone){
      return this.value?.telephone || ''
    }
    return null;
  }

}
