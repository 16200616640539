import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.value.length > 0)?_c('div',[_c('i18n',{attrs:{"path":"my-dossiers.uploaded-documents","tag":"h5"}}),_c(VList,[_c(VListItemGroup,_vm._l((_vm.value),function(document,index){return _c('div',{key:document.name},[_c(VListItem,{staticClass:"document-tile"},[_c(VListItemAvatar,[_c(VIcon,{staticClass:"pr-1 primary--text"},[_vm._v("mdi-file-document")])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(document.name)}})],1),_c(VListItemIcon,[_c(VIcon,{attrs:{"title":_vm.$t('my-documents.delete-document')},on:{"click":function($event){return _vm.deleteDocument(index)}}},[_vm._v("mdi-delete ")])],1)],1),(index < _vm.value.length - 1)?_c(VDivider):_vm._e()],1)}),0)],1)],1):_vm._e(),_c('div',[(_vm.value.length === 0)?_c('action-tile',{attrs:{"image":"no-documents.svg","icon":null,"max-image-width":_vm.maxImageWidth,"title":"my-documents.no-documents","subtitle":"my-documents.drag-drop-upload","droppable":true,"disabled":_vm.disabled},on:{"click":_vm.triggerFileInput,"filesDropped":_vm.update}}):_c('action-tile',{attrs:{"droppable":true,"disabled":_vm.disabled,"icon":"mdi-file-upload","title":"my-documents.drag-drop-upload-new"},on:{"click":_vm.triggerFileInput,"filesDropped":_vm.update}}),_c(VFileInput,{ref:"fileInput",staticClass:"pt-0 d-none",attrs:{"value":_vm.value,"accept":_vm.accept,"rules":_vm.rules,"multiple":_vm.multiple,"required":_vm.required},on:{"change":_vm.update}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }