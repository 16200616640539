











































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { validationRules } from "@/constants/ValidationRules";
import { ValidationError } from "@/app/dynamic-components/forms/form-validations/form-validator.base";

@Component({})
export default class AutocompleteWithErrors extends Vue {
  @Prop({ default: [] })
  errors!: ValidationError[];
  @Prop({ default: [] })
  errorMessages!: string[];

  @Prop({ default: false })
  dense!: boolean;

  @Prop({ default: false })
  multiple!: boolean;

  @Prop({ default: false })
  chips!: boolean;

  @Prop({ default: null })
  value!: unknown;

  @Prop({ default: false })
  clearable!: boolean;

  @Prop({ default: [] })
  items!: unknown[];

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: null })
  itemText!: string;

  @Prop({ default: null })
  itemValue!: string;

  @Prop({ default: null })
  label!: string;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  deletableChips!: boolean;

  @Prop({ default: false })
  translate!: boolean;

  @Prop({ default: false })
  autoSetOneItem!: boolean;

  @Prop({ default: false })
  sortAlphabetically!: boolean;

  autoCompleteValue: any = null;

  intermediateValue: any = null;

  @Watch("intermediateValue")
  updateIntermediateValue(): void {
    this.$emit("intermediateValue", this.intermediateValue);
  }

  mounted(): void {
    this.autoCompleteValue = this.value;
  }

  update(newValue: string): void {
    this.$emit("input", newValue);
  }

  @Watch("value")
  updateAutoCompleteValue(): void {
    this.autoCompleteValue = this.value;
  }

  get autocompleteItems(): unknown[] {
    if (this.items && this.items.length) {
      if (this.sortAlphabetically) {
        // Deep clone items
        const sortedItems = JSON.parse(JSON.stringify(this.items));

        sortedItems.sort((a: any, b: any) => {
          let labelA;
          let labelB;
          if (this.itemText) {
            labelA = a[this.itemText];
            labelB = b[this.itemText];
          } else {
            labelA = a;
            labelB = b;
          }

          if (this.translate) {
            labelA = this.$t(labelA);
            labelB = this.$t(labelB);
          }

          //console.warn("lavel", this.itemText, labelA, labelB, a, b)
          return labelA.localeCompare(labelB);
        });

        return sortedItems;
      } else {
        return this.items;
      }
    } else {
      return [];
    }
  }

  @Watch("items")
  setSingleValue(): void {
    if (
      this.items.length === 1 &&
      this.autoCompleteValue !== this.items[0] &&
      this.autoSetOneItem
    ) {
      if (this.multiple) {
        this.autoCompleteValue = [this.items[0]];

        if (this.autoCompleteValue.length === 1) {
          const newValue = this.autoCompleteValue.map(
            (val: any) => val[this.itemValue]
          );

          this.update(newValue);
        }
      } else {
        this.autoCompleteValue = this.items[0];

        if (this.autoCompleteValue !== null) {
          const newValue = this.autoCompleteValue[this.itemValue];

          this.update(newValue);
        }
      }
    }
  }
}
