









import { Component, Vue } from "vue-property-decorator";
import ActionTile from "@/app/components/common/ActionTile.vue";
import { Route } from "vue-router/types/router";
import router, { Routes } from "@/router";

@Component({
  components: {
    ActionTile,
  },
})
export default class MyDossiersTile extends Vue {
  navigateToMyDossiers(): Promise<Route> {
    return router.push({ name: Routes.MyDossiers });
  }
}
