


















































import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { Component } from "vue-property-decorator";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import Loader from "@/components/common/Loader.vue";
import { RenderType } from "@/app/Types";
import { exchangeService } from "@/app/services/exchange.service";
import ExchangeComponent from "@/app/components/exchange/exchange-component.vue";
import { ExchangeEvent } from "@/app/components/exchange/exchange.model";
import { Subject, Subscription } from "rxjs";
import {
  OverviewControl,
  OverviewEvent,
} from "@/app/dynamic-components/overviews/overiew.model";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import TextField from "@/components/common/TextField.vue";
import FileInput from "@/components/common/FileInput.vue";
import DossierDetailDocumentExchanges from "@/app/components/dossier-detail-document-exchanges.vue";
import DetailComponentExchangeSteps from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-exchange-steps.vue";
import {
  DynamicDetailEntry,
  ExchangeDefinition,
} from "@/app/dynamic-components/details/dynamic-detail.model";
import { detailComponentLookup } from "@/app/dynamic-components/details/details-components/detail-component.lookup";
import FormDialogComponent from "@/app/components/dialog/form-dialog-component.vue";
import {
  FormDialogControl,
  FormDialogModel,
} from "@/app/components/dialog/form-dialog.model";
import { ExternalContext } from "@/app/contexts/externalContext";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import { productService } from "@/app/services/product.service";
import { ruleEngine } from "@/app/services/rule.engine";
import store from "@/store";
import {checkinService} from "@/app/services/checkin.service";

export type DetailCheckinsMetaData = {
  overviewDefinitionId: string;
  createDialogDefinitionId: string
  checkoutDialogDefinitionId: string
  detailDialogDefinitionId: string
  path: string;
  filter: {[key: string]: string}
  referenceType: string;
  referenceId: string;
  linkName: string
  linkPath: string
  dialogWidth: string;
};

@Component({
  components: { FormDialogComponent, OverviewComponent, FileInput, TextField },
  computed: {
    RenderType() {
      return RenderType;
    },
  },
})
export default class DetailComponentCheckins extends DetailComponentBase<DetailCheckinsMetaData> {
  createCheckinControl: FormDialogControl | null = null;
  openCheckinControl: FormDialogControl | null = null;
  checkoutCheckininControl: FormDialogControl | null = null;
  overviewControl: OverviewControl | null = null;

  public additionalFilters: {[key: string]: string} = {};

  postEntityChanged(entry: DynamicDetailEntry) {
    if (entry) {
      if(this.metadata.filter && typeof this.metadata.filter === 'object'){
        Object.entries(this.metadata.filter).forEach(value => {
          const filter = value[0];
          const filterValue = value[1]
          this.additionalFilters[filter] = filterValue
        })
      }

      this.linkProductModal.referenceId = this.metadata.referenceId;
      this.linkProductModal.referenceType = this.metadata.referenceType;
      const activityDefinition = this.$store.state.activity
        .currentActivityDefinition as ActivityDefinitionModel;
      this.linkProductModal.externalContext.setData(
        "activitydefinition",
        activityDefinition
      );
    }
  }

  openCheckinDetails(item: { item: any; index: number }) {
    console.warn(item.item);
    this.openCheckinControl?.show(item.item.id, item.item);
  }

  openCreateCheckin() {
    this.createCheckinControl?.show('', {
      regardingId: this.detailContext.resolvePlaceholders(this.entry?.metadata?.referenceId),
      referenceIds: [this.detailContext.resolvePlaceholders(this.entry?.metadata?.referenceId)],
      regardingType: this.detailContext.resolvePlaceholders(this.entry?.metadata.referenceType),
      referenceId: this.detailContext.resolvePlaceholders(this.entry?.metadata.referenceId),
      markExecutorsAsBusy: true,
      linkName: this.detailContext.resolvePlaceholders(this.entry?.metadata.linkName),
      linkPath: this.detailContext.resolvePlaceholders(this.entry?.metadata.linkPath),
    });
  }

  openCheckoutCheckin(item: { item: any; index: number }) {
    console.warn(item.item);
    this.checkoutCheckininControl?.show(item.item.id, item.item);
  }

  recieveCreateCheckinModal(control: FormDialogControl) {
    this.createCheckinControl = control;
    control.submitChanges = this.createCheckin;
    control.afterSubmitChanges = this.refreshOverview;
  }

  recieveOpenCheckinModal(control: FormDialogControl) {
    this.openCheckinControl = control;
    control.submitChanges = this.modifyCheckin;
    control.afterSubmitChanges = this.refreshOverview;
  }

  recieveCheckoutCheckinModal(control: FormDialogControl) {
    this.checkoutCheckininControl = control;
    control.submitChanges = this.checkoutCheckin;
    control.afterSubmitChanges = this.refreshOverview;
  }

  recieveOverviewControl(control: OverviewControl) {
    this.overviewControl = control;
  }

  async createCheckin(
      form: DynamicForm,
      formData: any,
      referenceId?: string
  ): Promise<boolean> {
    try{
      if(!this.entry) return false;

      if(form.formData.referenceIds && Array.isArray(form.formData.referenceIds)){
        const forms = form.formData.referenceIds.map(refId => {
          const formData = JSON.parse(JSON.stringify(form.formData));
          formData['regardingId'] = refId;
          formData['referenceId'] = refId;
          return formData;
        });
        await checkinService.createCheckinBulk(form, forms);
      }else{
        await checkinService.createCheckin(form, form.formData);
      }
      return true;
    }catch (e) {
      return false;
    }
  }

  async modifyCheckin(
      form: DynamicForm,
      formData: any,
      referenceId?: string
  ): Promise<boolean> {
    try{
      if(!this.entry) return false;

      form.patchForm({
        regardingId: this.detailContext.resolvePlaceholders(
            this.entry.metadata.referenceId
        ),
        regardingType: this.detailContext.resolvePlaceholders(
            this.entry.metadata.referenceType
        ),
        referenceId: this.detailContext.resolvePlaceholders(
            this.entry.metadata.referenceId
        ),
        markExecutorsAsBusy: true,
        linkName: this.detailContext.resolvePlaceholders(
            this.entry.metadata.linkName
        ),
        linkPath: this.detailContext.resolvePlaceholders(
            this.entry.metadata.linkPath
        ),
      });

      await checkinService.updateCheckin(form, referenceId || '', form.formData, false);
      return true;
    }catch (e) {
      return false;
    }
  }

  async checkoutCheckin(
      form: DynamicForm,
      formData: any,
      referenceId?: string
  ): Promise<boolean> {
    try{
      form.patchForm({
        actualEndDate: new Date(),
        regardingType: this.metadata.referenceType
      });
      await checkinService.completeCheckin(form,referenceId || '',form.formData);
      return true;
    }catch (e) {
      console.error(e);
      return false;
    }
  }

  async linkProducts(
    form: DynamicForm,
    formData: any,
    referenceId?: string
  ): Promise<boolean> {
    if (Array.isArray(formData)) {
      const productIds = Array.from(formData);
      for (let i = 0; i < productIds.length; i++) {
        const success = await productService.linkProduct(
          productIds[i],
          this.metadata.referenceId,
          this.metadata.referenceType
        );
        if (!success) {
          return false;
        }
      }
    }
    return true;
  }

  async deleteProductLink(event: OverviewEvent) {
    if (event?.item?.product?.id) {
      await productService.unlinkProduct(event.item.product.id, event.item.id);
      await this.refreshOverview();
    }
  }

  async refreshOverview(): Promise<boolean> {
    this.overviewControl?.refresh();
    return true;
  }

  private linkProductModal: FormDialogModel = {
    submitLabel: "linkproduct.submit",
    referenceId: "",
    referenceType: "",
    destroyLabel: null,
    title: "linkproduct.title",
    externalContext: new ExternalContext(),
    form: {
      id: "root",
      name: "",
      type: RenderType.SelectFromOverview,
      displayMode: "editable",
      displayModeRule: "",
      computedValue: "",
      metadata: {
        path: `api/v2/products`,
        overviewDefinitionId: "432819ae-6d61-ee11-8df0-6045bd895bfb",
        selectableProperty: "id",
      },
      children: [],
    },
  };
}
