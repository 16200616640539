



















































import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import { roleService } from "@/app/services/role.service";
import AutocompleteWithErrors from "@/app/components/AutocompleteWithErrors.vue";
import { DynamicFormEntry } from "@/app/dynamic-components/forms/dynamic-form.model";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import {userServiceV2} from "@/app/services/user.service";
import UserInfo from "@/contracts/generic/UserInfo";

export type SelectOrgInRoleMetaData = {
  roleIdsOrganisation?: string[];
  roleIdsPerson?: string[];
  multipleUsers: boolean;
  translationKeyPerson: boolean;
};

@Component({
  components: { AutocompleteWithErrors, AeAutocomplete },
})
export default class FormComponentSelectOrgAndUserInRoles extends BaseDynamicComponent<SelectOrgInRoleMetaData> {
  orgs: string[] | string = [];
  eligibleOrganistions: { name: string; id: string }[] = [];
  loadingElegableOrganistions = false;

  currentFetchedElegablePersonsOrg = 'sqds';
  persons: string[] | string = [];
  eligiblePersons: { name: string; id: string }[] = [];
  loadingElegablePersons = false;
  currentlySelectedUser: UserInfo | null = null;

  preventOrgDetectiontrigger = false;

  getOrgErrors(){
    if(this.orgs?.length > 0){
      return this.errors.filter(value => value.id !== 'required');
    }
    return this.errors;
  }
  getOrgErrorMessages(){
    if(this.orgs?.length > 0){
      return [];
    }
    return this.errorMessages;
  }


  postEntityChanged(entry: DynamicFormEntry) {
    if (
      entry &&
      Array.isArray(entry?.metadata?.roleIdsOrganisation) &&
      Array.from(entry?.metadata?.roleIdsOrganisation).length > 0
    ) {
      roleService
        .getEligableOrganisations(Array.from(entry?.metadata?.roleIdsOrganisation)[0])
        .then((eligibleOrganistions) => {
          this.eligibleOrganistions = eligibleOrganistions;
        })
        .catch((reason) => {
          console.warn(
            "Failed to fetch EligableOrganisations for " +
              Array.from(entry?.metadata?.roleIdsOrganisation)[0] +
              " " +
              reason
          );
          this.eligibleOrganistions = [];
        });
    } else {
      this.eligibleOrganistions = [];
    }
  }

  get displayOrganisations(): string {
    return this.eligibleOrganistions
      .filter((eligibleOrg) => this.orgs.includes(eligibleOrg.id))
      .map((org) => org.name)
      .join(", ");
  }

  @Watch("orgs")
  onOrgsChanged() {
    const r = {
      org: this.orgs && Array.isArray(this.orgs) && this.orgs.length > 0 ? this.orgs[0] : (this.orgs as string || null),
      persons: []
    };

    if(!r.org){
      this.setValueView(null);
      this.currentFetchedElegablePersonsOrg = 'dsqsdqs'
      this.eligiblePersons = [];
    }
    if(r.org && r.org !== this.currentFetchedElegablePersonsOrg){
      this.currentFetchedElegablePersonsOrg = r.org;
      this.eligiblePersons = [];
      this.loadingElegablePersons = true;
      roleService
          .getEligibleUsersForRole(Array.from(this.entry?.metadata?.roleIdsPerson)[0] as string, false, Array.isArray(this.orgs) ? this.orgs[0] : this.orgs)
          .then((eligiblePersons) => {
            this.eligiblePersons = eligiblePersons.map((value) => {
              return { name: value.fullName, id: value.id };
            });
            this.loadingElegablePersons = false;
          })
          .catch((reason) => {
            console.warn(
                "Failed to fetch eligiblePersons for " +
                Array.from(this.entry?.metadata?.roleIds)[0] +
                " " +
                reason
            );
            this.eligiblePersons = [];
            this.loadingElegablePersons = false;
          });
    }

    if(this.preventOrgDetectiontrigger){
      this.preventOrgDetectiontrigger = false;
      return;
    }
    this.setValueView(r);
  }

  get displayPersons(): string {
    if (this.currentlySelectedUser)
      return (
          this.currentlySelectedUser.firstName +
          " " +
          this.currentlySelectedUser.lastName
      );
    return this.eligiblePersons
        .filter((person) => this.persons.includes(person.id))
        .map((person) => person.name)
        .join(", ");
  }

  @Watch("persons")
  onPersonsChanged() {
    const r = {
      org: this.orgs && Array.isArray(this.orgs) && this.orgs.length > 0 ? this.orgs[0] as string : (this.orgs || null),
      persons: this.persons
    };

    if(r.org === null || r.org === undefined || r.org === 'undefined' || r.org === ''){
      this.setValueView(null);
      return;
    }

    this.setValueView(r);
  }
  postSetValueView(value: any) {
    if(!value){
      this.orgs = [];
      this.persons = [];
      this.currentlySelectedUser = null;
      return;
    }

    if (value.org !== this.orgs) {
      if(value.persons !== this.persons){
        this.preventOrgDetectiontrigger = true;
      }
      this.orgs = value.org;
    }

    if (value.persons !== this.persons) {
      this.persons = value.persons;
      if (value.persons) {
        userServiceV2.getUsersByIds([value.persons]).then((value) => {
          this.currentlySelectedUser = value[0];
        });
      } else {
        this.currentlySelectedUser = null;
      }
    }

  }

  async afterSave(sumbitData: any): Promise<boolean> {
    console.log('aftersave', sumbitData, this.entry.value);
    if (!sumbitData?.type) return true;
    if (sumbitData.type === "dossier" && sumbitData.referenceId) {
      const r = this.entry.value;
      if(r){
        if (Array.isArray(r.org)) {
          for (let org of Array.from(r.org)) {
            await dossierServiceV2.addPartyToDossier(
                sumbitData.referenceId,
                "ORGANISATION",
                org as string,
                this.entry.metadata?.roleIdsOrganisation[0]
            );
          }
        } else {
          await dossierServiceV2.addPartyToDossier(
              sumbitData.referenceId,
              "ORGANISATION",
              r.org,
              this.entry.metadata?.roleIdsOrganisation[0]
          );
        }

        if (Array.isArray(r.persons)) {
          for (let user of Array.from(r.persons)) {
            await dossierServiceV2.addPartyToDossier(
                sumbitData.referenceId,
                "USER",
                user as string,
                this.entry.metadata?.roleIdsPerson[0]
            );
          }
        } else {
          await dossierServiceV2.addPartyToDossier(
              sumbitData.referenceId,
              "USER",
              r.persons,
              this.entry.metadata?.roleIdsPerson[0]
          );
        }
      }
    }
    return true;
  }

  fillIndexerValue(indexerField: string, indexerSelector): any {
    if('users' === indexerSelector){
      return this.eligiblePersons
          .filter((person) => this.persons.includes(person.id))
          .map((person) => person.name)
          .join(", ")|| "";
    }

    if('orgs' === indexerSelector){
      return this.eligibleOrganistions
          .filter((org) => this.orgs.includes(org.id))
          .map((org) => org.name)
          .join(", ")|| "";
    }

    return super.fillIndexerValue(indexerField, indexerSelector);
  }
}
