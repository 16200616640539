


























import { Component, Prop, Vue } from "vue-property-decorator";
import ActionTile from "@/app/components/common/ActionTile.vue";
import UploadDocumentDialog from "@/components/documents/upload/UploadDocumentDialog.vue";
import CreateDocumentDto from "@/contracts/document/CreateDocumentDto";

@Component({
  components: {
    UploadDocumentDialog,
    ActionTile,
  },
})
export default class UploadDocumentTile extends Vue {
  @Prop({ default: null })
  image!: string;

  @Prop({ default: null })
  icon!: string;

  @Prop({ default: "50vw" })
  maxImageWidth!: string;

  @Prop({ default: null })
  title!: string;

  @Prop({ default: null })
  subtitle!: string;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: null })
  submitLabel!: string;

  @Prop({ default: null })
  documentType!: string;

  @Prop({ default: null })
  dossierActivity!: string;

  showDialog = false;
  files: File[] | null = null;

  showUploadDialog(): void {
    if (!this.disabled) {
      this.files = null;
      this.showDialog = true;
    }
  }

  showUploadDialogWithFile(files: File[]): void {
    this.files = Array.from(files);
    this.showDialog = true;
  }

  closeDialog(): void {
    this.showDialog = false;
  }

  emitDocumentAddedEvent(document: CreateDocumentDto | null): void {
    this.$emit("documentAdded", document);
  }
}
