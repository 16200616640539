














































































































import { Vue, Component, Watch } from "vue-property-decorator";
import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import TextField from "@/components/common/TextField.vue";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import Autocomplete from "@/components/common/Autocomplete.vue";

type AddressFormMetaData = {
  allowCoordinates: boolean;
};

@Component({
  components: { Autocomplete, TextField, AeAutocomplete },
})
export default class AddressFormBase extends BaseDynamicComponent<AddressFormMetaData> {
  isAddressAvailble = true;

  mounted(): void {
    this.value = {
      country: '',
      municipality: '',
      postalCode: '',
      street: '',
      number: '',
      box: ''
    };
  }

  onSelectContentChanged(newValue: unknown, valueKey: string) {
    if (this.value == null) {
      this.setValueView({});
    }
    this.value[valueKey] = newValue;
  }

  onSelectValidityChanged(isValid: boolean) {
    return;
  }

  onAddressAvailableChange() {
    if (this.isAddressAvailble) {
      this.value["latitude"] = null;
      this.value["longitude"] = null;
    } else {
      this.value["street"] = null;
      this.value["number"] = null;
      this.value["box"] = null;
    }
  }

  fillIndexerValue(indexerField: string, indexerSelector): any {
    if(indexerField.startsWith('t')){
      if (!this.value || !this.value["municipality"]) return "";
      return (this.value["country"] || "") + " " + (this.value["postalCode"] || "") + " " + (this.value["municipality"] || "") + " " + (this.value["street"] || "") + " " + (this.value["number"] || "") + "" + (this.value["box"] || "")
    }
    if(indexerField.startsWith('n')){
      if (!this.value || !this.value["municipality"]) return "";
      return this.value && this.value["postalCode"] ? Number.parseInt(this.value["postalCode"]) : 0
    }
    if(indexerField.startsWith('b')){
       return this.value && this.value["municipality"];
    }
    return undefined;
  }
}
