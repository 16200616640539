import { ProductModel } from "@/app/models/product/product.model";
import axios from "@/plugins/axios";
import {
  CreatePlanningRequest,
  PlanningModel,
  UpdatePlanningRequest,
} from "@/app/models/planning/planning.model";
import {
  DynamicForm,
  DynamicFormEntry, IndexList,
} from "@/app/dynamic-components/forms/dynamic-form.model";
import store from "@/store";

class PlanningService {
  getPlanning(planningId: string): [AbortController, Promise<PlanningModel>] {
    const controller = new AbortController();
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/planning/${planningId}`;
    const prom = axios.get(url, {signal: controller.signal}).then((response) => {
      return this.convertGetPlanningToPlanning(response.data);
    });
    return [controller, prom];
  }

  async createPlanning(form: DynamicForm, create: any): Promise<any> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/planning`;
    const createRequest = this.convertFormDataToPlanningCreateRequest(
      form,
      create
    );
    return axios.post(url, createRequest).then((response) => {
      return response.data;
    });
  }

  async updatePlanning(
    form: DynamicForm,
    planningId: string,
    update: any
  ): Promise<any> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/planning/${planningId}`;
    const updateRequest = this.convertFormDataToPlanningUpdateRequest(
      form,
      update
    );
    return axios.put(url, updateRequest).then((response) => {
      return response.data;
    });
  }

  async addPartyToPlanning(
    contactId: string | null,
    roleId: string | null,
    planningId: string,
    contactType: string
  ): Promise<any> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/planning/${planningId}/party`;

    return axios
      .post(url, {
        contactId: contactId,
        roleId: roleId,
        portalId: contactId,
        contactType: contactType,
      })
      .then((response) => {
        return response.data;
      });
  }

  private convertGetPlanningToPlanning(apiResponse: any): PlanningModel {
    apiResponse = { ...apiResponse, ...apiResponse.dynamic_Properties };
    return apiResponse;
  }

  private convertFormDataToPlanningCreateRequest(
    form: DynamicForm,
    formdata: any
  ): CreatePlanningRequest {
    const mappedValues = DynamicFormEntry.SEPERATE_FIELDS(formdata, form);
    const result = mappedValues.data;
    result.dynamic_Properties = mappedValues.dynamicProperties;

    const indexers = new IndexList();
    form.resolveIndexers(indexers);
    result['indexers'] = indexers;

    return result;
  }

  private convertFormDataToPlanningUpdateRequest(
    form: DynamicForm,
    formdata: any
  ): UpdatePlanningRequest {
    const mappedValues = DynamicFormEntry.SEPERATE_FIELDS(formdata, form);
    const result = mappedValues.data;
    result.dynamic_Properties = mappedValues.dynamicProperties;

    const indexers = new IndexList();
    form.resolveIndexers(indexers);
    result['indexers'] = indexers;

    return result;
  }
}

export const planningService = new PlanningService();
