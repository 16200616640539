



























































































import { Component } from "vue-property-decorator";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import Loader from "@/components/common/Loader.vue";
import ActionTile from "@/app/components/common/ActionTile.vue";
import MyDocumentsView from "@/views/documents/MyDocumentsView.vue";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import {
  FormDialogControl,
  FormDialogModel,
} from "@/app/components/dialog/form-dialog.model";
import { ExternalContext } from "@/app/contexts/externalContext";
import FormDialogComponent from "@/app/components/dialog/form-dialog-component.vue";
import { RenderType } from "@/app/Types";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import { productService } from "@/app/services/product.service";
import { OverviewControl } from "@/app/dynamic-components/overviews/overiew.model";
import { Overview } from "@/app/models/overview/overview.model";

@Component({
  components: {
    FormDialogComponent,
    MyDocumentsView,
    ActionTile,
    Loader,
    AuthenticationInterceptor,
    AcceptPoliciesInterceptor,
    PageHeader,
    OverviewComponent,
  },
})
export default class ProductsOverviewView extends ActivityDependendViewBase {
  tabs: any

  additionalFiltersOwn: { [key: string]: string } = {};
  additionalMetadataOwn: { [key: string]: any } = {};

  additionalFiltersPublic: { [key: string]: string } = {};
  additionalMetadataPublic: { [key: string]: any } = {};

  overviewControl: OverviewControl | null = null;
  createNewProductControl: FormDialogControl | null = null;
  updateProductControl: FormDialogControl | null = null;

  async onPageReady(): Promise<any> {
    this.additionalFiltersOwn = {
      nrq_ispublic: 'false',
      nrq_ownerorganisation: this.$store.state.user.organizationId
    };
    this.additionalFiltersPublic = {
      nrq_ispublic: 'true'
    };

    const activityDefinition = this.$store.state.activity.currentActivityDefinition as ActivityDefinitionModel;
    this.additionalMetadataOwn.activitydefinition = activityDefinition;
    this.additionalMetadataPublic.activitydefinition = activityDefinition;
  }

  public recieveOverviewControl(control: OverviewControl) {
    this.overviewControl = control;
  }

  public async refreshOverview(): Promise<boolean> {
    this.overviewControl?.refresh();
    return true;
  }

  public mapApiProductToProduct(overview: Overview): Overview {
    overview._embedded.results = overview?._embedded?.results?.map((val) => {
      return productService.convertGetProductToProduct(val);
    });
    return overview;
  }

  recieveCreateNewProductControl(control: FormDialogControl) {
    this.createNewProductControl = control;
    control.submitChanges = this.saveNewProduct;
    control.afterSubmitChanges = this.refreshOverview;
  }

  public async saveNewProduct(
    form: DynamicForm,
    formData: any
  ): Promise<boolean> {
    if (formData.custom_materialType) {
      formData.materialType = formData.materialTypeManual;
    }
    if (formData.custom_manufacturer) {
      formData.manufacturer = formData.manufacturerManual;
    }
    formData.ownerContactId = this.$store.state.user.contactId;
    formData.ownerOrganisationId = this.$store.state.user.organizationId;
    return productService.createProduct(form, formData).then((value) => {
      this.createNewProductControl?.setReferenceId(value);
      return !!value;
    });
  }

  recieveUpdateProductControl(control: FormDialogControl) {
    this.updateProductControl = control;
    control.submitChanges = this.updateProduct;
    control.afterSubmitChanges = this.refreshOverview;
    control.destroyItem = this.deactivateProduct;
    control.afterDestroyItem = this.refreshOverview;
  }

  public async deactivateProduct(
    form: DynamicForm,
    formData: any,
    referenceId: string
  ): Promise<boolean> {
    if (formData.custom_materialType) {
      formData.materialType = formData.materialTypeManual;
    }
    if (formData.custom_manufacturer) {
      formData.manufacturer = formData.manufacturerManual;
    }
    formData.ownerContactId = this.$store.state.user.contactId;
    formData.ownerOrganisationId = this.$store.state.user.organizationId;
    return productService
      .deleteProduct(form, referenceId, formData)
      .then((value) => !!value);
  }

  public async updateProduct(
    form: DynamicForm,
    formData: any,
    referenceId?: string
  ): Promise<boolean> {
    if (formData.custom_materialType) {
      formData.materialType = formData.materialTypeManual;
    }
    if (formData.custom_manufacturer) {
      formData.manufacturer = formData.manufacturerManual;
    }
    this.updateProductControl?.setReferenceId(referenceId || "nan")
    formData.ownerContactId = this.$store.state.user.contactId;
    formData.ownerOrganisationId = this.$store.state.user.organizationId;
    return productService
      .updateProduct(form, this.updateProductControl?.getReferenceId() || "nan", formData)
      .then((value) => !!value);
  }

  showCreateDialog() {
    this.createNewProductControl?.show();
  }

  showUpdateDialog(item: { item: any; index: number }) {
    console.warn("item", item);
    this.updateProductControl?.show(item.item.id, item.item);
  }

}
