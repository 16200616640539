



















import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import {Component, Watch} from "vue-property-decorator";
import TimePicker from "@/components/common/TimePicker.vue";
import DateTimePickerBase from "@/app/dynamic-components/forms/form-components/components/form-component-datetimepicker.vue";
import AeDatePicker from "@/components/common/DatePicker.vue";

export type TimePickerMetaData = {
  disabled?: boolean;
};

@Component({
  components: {AeDatePicker, TimePicker },
})
export default class TimePickerBase extends BaseDynamicComponent<TimePickerMetaData> {
  pickerTime: Date | null = null;
  hours = '';
  minutes = '';
  postSetValueView(value: any) {
    if (!value && value !== "") {
      this.pickerTime = null;
    } else {
      this.pickerTime = new Date();
      let hours = value.split(":").splice(0, 1);
      this.hours = hours < 10 ? '0'+hours : ''+hours;
      let minutes = value.split(":").splice(1, 2);
      this.minutes = minutes < 10 ? '0'+minutes : ''+minutes

      this.pickerTime.setHours(hours);
      this.pickerTime.setMinutes(minutes);
    }
  }

  onTimePickerValueChanged(newValue: Date): void {
    this.pickerTime = newValue;
    console.warn('time ticked', newValue);
    this.setValueView(
      newValue.toLocaleString().split(" ")[1].split(":").splice(0, 2).join(":")
    );
  }


  fillIndexerValue(indexerField: string, indexerSelector): any {
    if(indexerField.startsWith('t')){
      return this.value ? this.hours + ':' + this.minutes : ''
    }
    if(indexerField.startsWith('n')){
      return this.value ? ((60 * Number.parseInt(this.hours)) + this.minutes) : 0
    }
    if(indexerField.startsWith('b')){
      return !!this.value;
    }
    return undefined;
  }
}
