





































import {Component, Prop, Watch} from "vue-property-decorator";
import Vue from "vue";
import FormComponentTreeCheckbox, {TreeEntry} from "@/app/dynamic-components/forms/form-components/components/form-component-tree-checkbox.vue";


@Component({
  components: {FormComponentTreeCheckboxEntry}
})
export default class FormComponentTreeCheckboxEntry extends Vue {
  @Prop({ default: null })
  me!: FormComponentTreeCheckbox;

  @Prop({ default: [] })
  tree!: TreeEntry[];

  @Watch("tree", { immediate: true, deep: true })
  setTree(newTree:  TreeEntry[], oldTree:  TreeEntry[]) {
    if(newTree){
      newTree.forEach(value => {
        this.showChilderen[''+value.id] = true;
      })
    }
  }

  showChilderen: {[key: string]: boolean} = {};

  toggleChildren(s: string){
    this.showChilderen[''+s] = !this.showChilderen[''+s];
    this.$forceUpdate();
  }

  public anyChildIsChecked(item: TreeEntry){
    return !(item.children.filter(value => !value.state).length > 0);
  }

  onCheckBoxValueChanged(entry: TreeEntry, filled: boolean): void {
    let newValue: any;
    if (!filled) {
      newValue = entry.templateValue === true ? false : null;
    }else{
      newValue = entry.templateValue;
    }

    if(entry.state !== newValue){
      entry.state = newValue;
      if(this.me.entry.metadata.autoSelectChildren){
        this.me.iterateChildrenDown(filled, entry);
      }
      this.me.iterateChildrenUp(entry);
      this.me.onCheckBoxValueChanged();
    }
  }
}
