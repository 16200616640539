


























import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { Component } from "vue-property-decorator";
import { detailComponentLookup } from "@/app/dynamic-components/details/details-components/detail-component.lookup";

export type DetailDocumentsMetaData = unknown;

@Component({})
export default class DetailComponentTabs extends DetailComponentBase<DetailDocumentsMetaData> {
  currentTab = "";

  getDossierDetailComponent(type: string) {
    this.$store
    return detailComponentLookup.getComponentFromDictionary(type);
  }
}
