
























import { Component } from "vue-property-decorator";
import { ComponentTypeDictionaryService } from "@/app/dynamic-components/forms/form-components/form-component.lookup";
import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import BaseDynamicCompositeComponent from "@/app/dynamic-components/forms/form-components/form-component-composite.base";
import { RenderItem, RenderType } from "@/app/Types";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import FormComponentExternalEntityBase from "@/app/dynamic-components/forms/form-components/form-component-ext-entity.base";
import { PlanningModel } from "@/app/models/planning/planning.model";
import { planningService } from "@/app/services/planning.service";
import Loader from "@/components/common/Loader.vue";
import {dateToIndexerValue} from "@/app/helpers/date.helper";

export type PlannerMetaData = {
  label: string;
  disabled?: boolean;
  referenceType: string;
  activitiesSelector: string;
};

@Component({
  components: { Loader, FormWrapper },
  computed: {
    ComponentTypeDictionaryService() {
      return ComponentTypeDictionaryService;
    },
  },
})
export default class PlannerBase extends FormComponentExternalEntityBase<
  PlannerMetaData,
  PlanningModel
> {
  protected async fetchExternalEntity(
    value: any
  ): Promise<PlanningModel | null> {
    return planningService.getPlanning(value)[1];
  }

  protected async createExternalEntity(formData: any): Promise<string> {
    if (!this.form) return "";

    let activities = "";
    if (this.entry.metadata.activitiesSelector) {
      activities =
        this.entry.root?.resolveDataPath(
          this.entry.metadata.activitiesSelector
        ) || "";
    }

    this.form.patchForm({
      responsableContactId: this.$store.state.user.contactId,
      responsableOrganisationId: this.$store.state.user.organizationId,
      activities: activities,
    });
    return planningService.createPlanning(this.form, this.form.formData);
  }

  protected async createExternalEntityAfterSave(
    sumbitData: any
  ): Promise<void> {
    if (!this.form || !this.externalEntityId) return;

    this.form.patchForm({
      regardingId: sumbitData.referenceId,
      regardingType: this.entry.metadata.referenceType,
      referenceId: sumbitData.referenceId,
    });

    this.form.patchForm({
      linkPath:
        this.entry.root?.resolvePlaceholders(this.entry.metadata.linkPath) ||
        "",
      linkName:
        this.entry.root?.resolvePlaceholders(this.entry.metadata.linkName) ||
        "",
    });
    await planningService.updatePlanning(
      this.form,
      this.externalEntityId,
      this.form.formData
    );
  }

  protected async updateExternalEntity(
    externalEntityId: string,
    externalEntity: PlanningModel | null,
    formData: any
  ) {
    if (!this.form) return false;

    let activities = "";
    if (this.entry.metadata.activitiesSelector) {
      activities =
        this.entry.root?.resolveDataPath(
          this.entry.metadata.activitiesSelector
        ) || "";
    }

    if (this.entry.metadata.linkPath) {
      this.form.patchForm({
        activities: activities,
        linkPath:
          this.entry.root?.resolvePlaceholders(this.entry.metadata.linkPath) ||
          "",
        linkName:
          this.entry.root?.resolvePlaceholders(this.entry.metadata.linkName) ||
          "",
      });
    }
    return planningService.updatePlanning(
      this.form,
      externalEntityId,
      this.form.formData
    );
  }

  fillIndexerValue(indexerField: string, indexerSelector): any {
    const formdata = this.form?.formData;
    console.warn("fillIndexerValue", formdata, this.value);
    if(!formdata) return null;

    if('startdate' === indexerSelector){
      return dateToIndexerValue(indexerField, formdata.startDate);
    }
    if('enddate' === indexerSelector){
      return dateToIndexerValue(indexerField, formdata.endDate);
    }
    if('description' === indexerSelector){
      return formdata.description|| "";
    }
    if('linkpath' === indexerSelector){
      return formdata.linkPath|| "";
    }
    if('linkname' === indexerSelector){
      return formdata.linkName|| "";
    }
    if('planner' === indexerSelector){
      return dateToIndexerValue(indexerField, formdata.startDate) + " " + dateToIndexerValue(indexerField, formdata.endDate);
    }

    return super.fillIndexerValue(indexerField, indexerSelector);
  }

  protected createDefinition(): RenderItem | null {
    if (!this.entry) return null;
    const mode = this.entry.resolvedDisplayMode;

    return {
      id: "root",
      name: "",
      type: RenderType.LinearSection,
      displayMode: mode,
      displayModeRule: "",
      computedValue: "",
      validators: [],
      metadata: {
        direction: "vertical",
      },
      children: [
        {
          id: "startDate",
          name: this.$tc("planning.startDate"),
          type: RenderType.DatePicker,
          displayMode: mode,
          displayModeRule: "",
          computedValue: "",
          validators: this.entry.validatorsNames,
          metadata: {},
        },
        {
          id: "endDate",
          name: this.$tc("planning.endDate"),
          type: RenderType.DatePicker,
          displayMode: mode,
          displayModeRule: "",
          computedValue: "",
          validators: this.entry.validatorsNames,
          metadata: {},
        },
        {
          id: "description",
          name: this.$tc("planning.description"),
          type: RenderType.TextArea,
          displayMode: mode,
          displayModeRule: "",
          computedValue: "",
          validators: [],
          metadata: {},
        },
        {
          id: "regardingId",
          name: "",
          type: RenderType.TEXTFIELD,
          displayMode: "hidden",
          displayModeRule: "",
          computedValue: "",
          validators: [],
          metadata: {},
        },
        {
          id: "regardingType",
          name: "",
          type: RenderType.TEXTFIELD,
          displayMode: "hidden",
          displayModeRule: "",
          computedValue: "",
          validators: [],
          metadata: {},
        },
        {
          id: "referenceId",
          name: "",
          type: RenderType.TEXTFIELD,
          displayMode: "hidden",
          displayModeRule: "",
          computedValue: "",
          validators: [],
          metadata: {},
        },
        {
          id: "responsableContactId",
          name: "",
          type: RenderType.TEXTFIELD,
          displayMode: "hidden",
          displayModeRule: "",
          computedValue: "",
          validators: [],
          metadata: {},
        },
        {
          id: "responsableOrganisationId",
          name: "",
          type: RenderType.TEXTFIELD,
          displayMode: "hidden",
          displayModeRule: "",
          computedValue: "",
          validators: [],
          metadata: {},
        },
        {
          id: "activities",
          name: "",
          type: RenderType.TEXTFIELD,
          displayMode: "hidden",
          displayModeRule: "",
          computedValue: "",
          validators: [],
          metadata: {
            isDynamicApiField: true,
          },
        },
        {
          id: "linkName",
          name: "",
          type: RenderType.TEXTFIELD,
          displayMode: "hidden",
          displayModeRule: "",
          computedValue: "",
          validators: [],
          metadata: {
            isDynamicApiField: true,
          },
        },
        {
          id: "linkPath",
          name: "",
          type: RenderType.TEXTFIELD,
          displayMode: "hidden",
          displayModeRule: "",
          computedValue: "",
          validators: [],
          metadata: {
            isDynamicApiField: true,
          },
        },
      ],
    };
  }
}
