




























import { Component } from "vue-property-decorator";
import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import Loader from "@/components/common/Loader.vue";
import { planningService } from "@/app/services/planning.service";
import { Subscription } from "rxjs";
import {dateFormat} from "@vuejs-community/vue-filter-date-format";

@Component({
  methods: {dateFormat},
  components: { Loader },
})
export default class OverviewCellPlanning extends OverviewCellBase {
  public loading = false;
  public startDate: any = null;
  public endDate: any = null;
  private controller: AbortController | undefined;
  eventbusSubcription: Subscription | undefined = undefined;

  mounted(): void {
    this.eventbusSubcription = this.eventbus?.subscribe((val) => {
      if (val.type === "FETCHING_NEW_DATA") {
        if(this.controller) {
          this.controller.abort();
          this.controller = undefined;
        }
      }
    });
  }

  protected PostResolveValue() {
    if (this.value) {
      this.loading = true;
      const planningResult = planningService.getPlanning(this.value);
      this.controller = planningResult[0];
      planningResult[1].then(value => {
          this.controller = undefined;
          this.startDate = value.startDate;
          this.endDate = value.endDate;
          this.loading = false;
        })
        .catch((reason) => {
          this.controller = undefined;
          this.loading = false;
        });
    }
  }


  beforeDestroy() {
    if(this.controller) this.controller.abort();
    super.beforeDestroy();

    if (this.eventbusSubcription) this.eventbusSubcription.unsubscribe();
    this.eventbusSubcription = undefined;
  }
}
