




















import { Component, Prop, Vue } from "vue-property-decorator";
import ActionTile from "@/app/components/common/ActionTile.vue";
import { roleBasedActionsService } from "@/services/role-based-actions.service";
import SelectActivityDialog from "@/components/dossiers/create/SelectActivityDialog.vue";
import store from "@/store";

@Component({
  components: {
    ActionTile,
    SelectActivityDialog,
  },
})
export default class MyDossiersTile extends Vue {
  @Prop({ default: null })
  image!: string;

  @Prop({ default: null })
  icon!: string;

  @Prop({ default: null })
  title!: string;

  @Prop({ default: null })
  subtitle!: string;

  @Prop({ default: "50vw" })
  maxImageWidth!: string;

  showDialog = false;

  get userIsAuthorizedToCreateDossier(): boolean {
    return (
      roleBasedActionsService.userIsAuthorizedToCreateDossier() &&
      store.state.userDossierAccess.hasAccessToDossiers
    );
  }

  closeDialog(): void {
    this.showDialog = false;
  }

  openDialog(): void {
    this.showDialog = true;
  }
}
